import { initContract } from '@ts-rest/core'
import { z } from 'zod'
import {
  TCostBreakdownByExchangeResponse,
  TExchangeAvgPostTgePopPerformanceResponse,
  TExchangeKpiChartResponse,
  TExchangeProfile,
  TExchangeRoiChartResponse,
  TExchangesRankedResponse,
  TExchangeTradingStatistics,
  TListingFeesRequest,
  TPreferences,
} from '../types'
import { ProjectPathParam } from '../types/common'
import {
  ExchangeQuestionnaireCategoriesArray,
  ExchangeQuestionnaireDocDescArray,
  ExchangeMcapTierEnum,
} from '@forgd/supabase'

const c = initContract()

export const exchangeListingContract = c.router({
  getRankedExchanges: {
    method: 'GET',
    path: `exchange-listing/exchanges/ranked`,
    responses: {
      200: TExchangesRankedResponse,
    },
    query: z.object({
      limit: z.number({ coerce: true }).default(10),
    }),
    summary: 'Get ranked exchanges',
  },
  getPreferences: {
    method: 'GET',
    path: `exchange-listing/preferences/:projectId`,
    responses: {
      200: TPreferences,
    },
    pathParams: ProjectPathParam,
    summary: 'Get preferences',
  },
  getExchangeProfile: {
    method: 'GET',
    path: `exchange-listing/exchange-profile/:projectId`,
    query: z.object({
      exchangeId: z.string().trim().min(1),
    }),
    pathParams: ProjectPathParam,
    responses: {
      200: TExchangeProfile,
    },
    summary: 'Get preferences',
  },
  getListingFees: {
    method: 'GET',
    path: `exchange-listing/:projectId/listing-fees`,
    responses: {
      200: z.object({
        totalFees: z.number().nonnegative(),
        activeExchanges: z.number().int(),
        nonRefundableFees: z.object({
          stablecoinUSD: z.number().int(),
          tokenUSD: z.number().nonnegative(),
          tokenSupplyPercentage: z.number(),
          totalUSD: z.number().nonnegative(),
          totalPercentage: z.number(),
        }),
        refundableFees: z.object({
          stablecoinUSD: z.number().int(),
          tokenUSD: z.number().nonnegative(),
          tokenSupplyPercentage: z.number(),
          totalUSD: z.number().nonnegative(),
          totalPercentage: z.number(),
        }),
        feesEstimationSummary: z.object({
          data: z.array(
            z.object({
              index: z.number().int().nonnegative(),
              'Non-Refundable Fees': z.number(),
              'Refundable Fees': z.number(),
              total: z.number(),
            }),
          ),
          meta: z.object({
            xAxis: z.string(),
            xFormat: z.string(),
            labels: z.array(z.string()),
          }),
        }),
        feesByCategory: z.object({
          data: z.array(
            z.object({
              index: z.number().int().nonnegative(),
              value: z.number(),
            }),
          ),
          meta: z.object({
            xAxis: z.string(),
            xFormat: z.string(),
            labels: z.array(z.string()),
          }),
        }),
      }),
    },
    query: TListingFeesRequest,
    pathParams: ProjectPathParam,
    summary: 'Get listing fee calculations',
  },
  getCostBreakdownByExchange: {
    method: 'GET',
    path: `exchange-listing/cost-breakdown-by-exchange/:projectId`,
    responses: {
      200: TCostBreakdownByExchangeResponse,
    },
    pathParams: ProjectPathParam,
    query: z.object({
      limit: z.number({ coerce: true }).default(10),
    }),
    summary: 'Get cost breakdown by exchange',
  },
  getQuestions: {
    method: 'GET',
    path: `exchange-listing/:projectId/questions`,
    responses: {
      200: z.object({
        items: z.array(
          z.object({
            id: z.string().uuid(),
            exchange: z.object({
              name: z.string(),
              logoUrl: z.string().nullable(),
            }),
            number: z.number().positive(),
            documentDescription: z.enum(ExchangeQuestionnaireDocDescArray),
            question: z.string(),
            category: z.enum(ExchangeQuestionnaireCategoriesArray),
            type: z.string(),
          }),
        ),
        page: z.number().int().positive(),
        pageCount: z.number().int().nonnegative(),
        itemCount: z.number().int().nonnegative(),
      }),
    },
    pathParams: ProjectPathParam,
    query: z.object({
      page: z.number({ coerce: true }).int().positive().default(1),
      pageSize: z.number({ coerce: true }).int().positive().default(40),
      exchangeId: z.string().optional(),
      category: z.enum(ExchangeQuestionnaireCategoriesArray).optional(),
    }),
    summary:
      'Get exchange listing questionaire questions, paginated and filtered',
  },
  getAvgPostTgePopPerformance: {
    method: 'GET',
    path: `exchange-listing/avg-post-tge-pop-performance`,
    responses: {
      200: TExchangeAvgPostTgePopPerformanceResponse,
    },
    query: z.object({
      year: z.number({ coerce: true }).optional(),
      quarter: z.number({ coerce: true }).optional(),
    }),
    summary: 'Get avg post tge pop performance',
  },
  getPerformanceQuarters: {
    method: 'GET',
    path: `exchange-listing/performance-quarters`,
    responses: {
      200: z.array(
        z.object({
          year: z.number(),
          quarter: z.number(),
        }),
      ),
    },
    summary: 'Get available post tge pop performance quarters',
  },
  getExchangeTradingStatistics: {
    method: 'GET',
    path: `exchange-listing/trading-statistics`,
    responses: {
      200: z.array(TExchangeTradingStatistics),
    },
    query: z.object({
      mcap: z.nativeEnum(ExchangeMcapTierEnum),
    }),
    summary: 'Get exchanges trading statistics',
  },
  getExchangeKpiChart: {
    method: 'GET',
    path: `exchange-listing/exchange-kpi-chart`,
    responses: {
      200: TExchangeKpiChartResponse,
    },
    query: z.object({
      exchangeId: z.string().trim().min(1),
      metric: z.enum(['avg24hVol', 'avgDepth', 'avgSpread']),
    }),
    summary: 'Get exchange kpi chart data',
  },
  getExchangeRoiChart: {
    method: 'GET',
    path: `exchange-listing/exchange-roi-chart`,
    responses: {
      200: TExchangeRoiChartResponse,
    },
    query: z.object({
      exchangeId: z.string().trim().min(1),
    }),
    summary: 'Get exchange roi chart data',
  },
})

export type ExchangeListingContractType = typeof exchangeListingContract
